import * as React from "react";
import { useState } from "react";
import Step1 from "../components/pdfSteps/Step1";
import Step2 from "../components/pdfSteps/Step2";
import Step3 from "../components/pdfSteps/Step3";
import Step4 from "../components/pdfSteps/Step4";
import Step5 from "../components/pdfSteps/Step5";
import Step6 from "../components/pdfSteps/Step6";
import Step7 from "../components/pdfSteps/Step7";
import Step8 from "../components/pdfSteps/Step8";
import Step9 from "../components/pdfSteps/Step9";
import IconArrowList from "../components/icons/IconArrowList";
import IconCloseModal from "../components/icons/IconCloseModal";

export default function StepsPDF({openModal}) {
  const [step, setStep] = useState(1);
  const [modalOpen, setModalOpen] = useState(true);
  const [formData, setFormData] = useState({
    fullname: '',
    registrationAddress: '',
    differentAddress: 'false',
    personalAddress: '',
    email: '',
    phone: '',
    birthday: '',
    specificDate: 'true',
    dateOn: '',
    dateUntil: '',
    signatureReason: '',
    helperName: '',
    helperAddress: ''
  });

  const handleChange = (e) => {
    // Set Form Data
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
  }

  const handleClick = (e) => {
    e.preventDefault();
    const target = e.target.id;
    target === 'continue' && setStep(step + 1);
    target === 'back' && setStep(step - 1);
  }

  const returnStep = () => {
    switch (step) {
      case 0:
        return <Step1 formData={formData} handleClick={(e) => handleClick(e)} handleChange={(e) => handleChange(e)}/>

      case 1:
        return <Step2 formData={formData} handleClick={(e) => handleClick(e)} handleChange={(e) => handleChange(e)} setFormData={setFormData}/>
    
      case 2:
        return <Step3 formData={formData} handleClick={(e) => handleClick(e)} handleChange={(e) => handleChange(e)}/>
    
      case 3:
        return <Step4 formData={formData} handleClick={(e) => handleClick(e)} handleChange={(e) => handleChange(e)}/>
    
      case 4:
        return <Step5 formData={formData} handleClick={(e) => handleClick(e)} handleChange={(e) => handleChange(e)}/>
    
      case 5:
        return <Step6 formData={formData} handleClick={(e) => handleClick(e)} handleChange={(e) => handleChange(e)} setFormData={setFormData}/>

      case 6:
        return <Step7 formData={formData} handleClick={(e) => handleClick(e)} handleChange={(e) => handleChange(e)}/>

      case 7:
        return <Step8 formData={formData} handleClick={(e) => handleClick(e)} handleChange={(e) => handleChange(e)}/>

      case 8:
        return <Step9 formData={formData} handleClick={(e) => handleClick(e)} handleChange={(e) => handleChange(e)}/>
    
      default:
        return <Step1 formData={formData} handleClick={(e) => handleClick(e)} handleChange={(e) => handleChange(e)}/>
        break;
    }
  }


  return(
    <section className="overflow-steps" style={{display: `${modalOpen ? 'block' : 'none'}`}}>
      <IconCloseModal setModalOpen={setModalOpen} />
      <div className="container">
        <div className="row">

          <div className="col-md-4">
            <div className="overflow__intro">
              <h2>Apply for a postal vote</h2>
              <p>Please, provide all of the information requested. By the end of the process, a print-ready PDF document will be generated.</p>
              <p>You MUST print a physical copy of the document and sign it by hand with BLACK ink, and send it back to us by mail or email by 5pm on 9th March.</p>
            </div>
            <div className="overflow__list">
              <h2>Remember</h2>
              <ul>
                <li><IconArrowList/>Fill in the form</li>
                <li><IconArrowList/>Print it out</li>
                <li><IconArrowList/>Sign it by hand</li>
                <li><IconArrowList/>Send it back to us</li>
              </ul>
            </div>
          </div>

          <div className="col-md-6 offset-md-1">
            {
              returnStep()
            }
          </div>

        </div>
      </div>
    </section>
  )
}